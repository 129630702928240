import * as React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Page from "../pages/page";
import Profile from "../pages/profile";
import Support from "../pages/support";
import Register from "../pages/register";
import Login from "../pages/login";
import Forgot from "../pages/forgot";
import Reset from "../pages/reset";
import Change from "../pages/change";
import Activate from "../pages/activate";
import Home from "../pages/home";
import Advertising from "../pages/advertising";
import Destinations from "../pages/destinations";
import Lists from "../pages/lists";
import List from "../pages/list";
import Meetings from "../pages/meetings";
import Lodging from "../pages/lodging";
import Sports from "../pages/sports";
import Calendar from "../pages/calendar";
import Events from "../pages/events";
import Event from "../pages/event";
import Eventcategory from "../pages/eventcategory";
import Destination from "../pages/destination";
import Category from "../pages/category";
import Experiences from "../pages/experiences";
import Experience from "../pages/experience";
import Activity from "../pages/activity";
import Stay from "../pages/stay";
import Tips from "../pages/tips";
import Tip from "../pages/tip";
import Posts from "../pages/posts";
import Post from "../pages/post";
import Town from "../pages/town";
import UnprotectedRoute from "../UnprotectedRoute";
import ProtectedRoute from "../ProtectedRoute";

const protected_routes = [
  //{ path: "/profile", exact: true, name: "Profile", component: Profile },
];

const routes = [
  //{ path: "/advertising", exact: true, name: "Advertising", component: Advertising },
  { path: "/destinations", exact: true, name: "Destinations", component: Destinations },
  { path: "/calendar", exact: true, name: "Calendar", component: Calendar },
  { path: "/meetings", exact: true, name: "Meetings", component: Meetings },
  { path: "/lodging", exact: true, name: "Lodging", component: Lodging },
  { path: "/sports", exact: true, name: "Sports", component: Sports },
  { path: "/tips", exact: true, name: "Tips", component: Tips },
  { path: "/tips/:slug", exact: false, name: "Tips", component: Tip },
  { path: "/itineraries", exact: true, name: "Lists", component: Lists },
  { path: "/itineraries/:slug", exact: false, name: "Lists", component: List },
  { path: "/experiences/:id", exact: false, name: "Experience", component: Experience },
  { path: "/experiences", exact: true, name: "Experiences", component: Experiences },
  { path: "/towns/:id", exact: false, name: "Town", component: Town },
  { path: "/d/:id", exact: false, name: "Destination", component: Destination },
  { path: "/c/:slug", exact: false, name: "Category", component: Category },
  { path: "/adventure", exact: true, name: "Experiences", component: Experiences },
  { path: "/activity/:id", exact: false, name: "Activity", component: Activity },
  { path: "/stay", exact: true, name: "Stay", component: Stay },
  { path: "/news", exact: true, name: "Posts", component: Posts },
  { path: "/posts/:id", exact: false, name: "Posts", component: Post },
  { path: "/events", exact: true, name: "Events", component: Events },
  { path: "/events/category/:slug", exact: false, name: "Events", component: Eventcategory },
  { path: "/events/:slug", exact: false, name: "Events", component: Event },
  { path: "/support", exact: true, name: "Support", component: Support },
  { path: "/forgot", exact: true, name: "Forgot", component: Forgot },
  { path: "/reset/:token", exact: true, name: "Reset", component: Reset },
  { path: "/profile", exact: true, name: "Profile", component: Profile },
];

const unprotected_routes = [
  { path: "/register", exact: true, name: "Register", component: Register },
  { path: "/login", exact: true, name: "Login", component: Login },
];

const Navigation = () => {
  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route
        exact={true}
        path={'/'}
        component={props => <Home {...props} />}
      />
      {routes.map((route, idx) => (
        <Route
          key={idx}
          exact={route.exact}
          path={route.path}
          render={props => <route.component {...props} />}
        />
      ))}
      {protected_routes.map((route, idx) => (
        <ProtectedRoute
          key={idx}
          exact={route.exact}
          path={route.path}
          component={props => <route.component {...props} />}
        />
      ))}
      {unprotected_routes.map((route, idx) => (
        <UnprotectedRoute
          key={idx}
          exact={route.exact}
          path={route.path}
          component={props => <route.component {...props} />}
        />
      ))}
      <Route exact path="/headless-home">
          <Redirect to="/" />
      </Route>
      <Route
          key="pageroute"
          exact={false}
          path={"*"}
          render={props => <Page {...props} />}
        />
      <Redirect to="/" />
    </Switch>
  );
};

export default Navigation;
