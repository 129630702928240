import * as React from "react";
import { ReactElement, useEffect, useState, useRef } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  SpaceProps,
  HStack,
  Tag,
  Link,
  Center,
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import headerBg from "../assets/images/header-background.jpg";
import footerBg from "../assets/images/footer-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import parser from "html-react-parser";
import { MotionBox } from "../components/motion";
import { MapContainer, TileLayer, Marker, Popup, useMap, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLng } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { CrowdRiffGallery } from 'react-crowdriff-gallery';

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const List = ({ match }) => {
  const toast = useToast();

  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);

  const position = [40.7880456, -77.870124];

  const initialMarkers = [];
  const [markers, setMarkers] = useState(initialMarkers);

  const LocationMarkers = () => {
    return (
      <React.Fragment>
        {markers.map((marker, index) => <Marker
          position={marker.location}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
          eventHandlers={{
            click: (e) => {
              //setSelectedDestination(marker.data);
              //onOpen();
            },
          }}>
          <Popup>
            <Link href={`/d/${marker.data.destination.slug}`}>#{index+1} {marker.data.destination.name}</Link><br />
            {marker.data.destination.address}<br />
            {marker.data.destination.city}, {marker.data.destination.state} {marker.data.destination.zip}<br />
            <Link href={`tel:+1${marker.data.destination.phone}`}>{marker.data.destination.phone}</Link>
          </Popup>
          <Tooltip>
            <strong>#{index+1} {marker.data.destination.name}</strong><br />
            {marker.data.destination.address}<br />
            {marker.data.destination.city}, {marker.data.destination.state} {marker.data.destination.zip}
          </Tooltip>
        </Marker>)}
      </React.Fragment>
    );
  }

  const LocationMarker = ({ destination }) => {
    return (
      <React.Fragment>
        <Marker
          position={[destination.location_formatted_latitude,destination.location_formatted_longitude]}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
          >
          <Popup>
            <Link href={`/d/${destination.slug}`}>{destination.name}</Link><br />
            {destination.address}<br />
            {destination.city}, {destination.state} {destination.zip}<br />
            <Link href={`tel:+1${destination.phone}`}>{destination.phone}</Link>
          </Popup>
          <Tooltip>
            <strong>{destination.name}</strong><br />
            {destination.address}<br />
            {destination.city}, {destination.state} {destination.zip}
          </Tooltip>
        </Marker>
      </React.Fragment>
    );
  }

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  const getEvent = () => {
    let data = {};
    axios.get(`${apiHost}/itineraries/get/${match.params.slug}`)
      .then(res => {
        data = res.data;
        console.log('event data:');
        console.log(data);
        if (res.status == 200) {
          setEvent(data);

          let geomarkers = [];
          data.items_list.forEach(marker => {
            if (marker.destination) {
              geomarkers.push({ data: marker, location: new LatLng(marker.destination.location_latitude, marker.destination.location_longitude) })
            }
          });
          setMarkers(geomarkers);

          setLoading(false);
        }
        return res;
      })
      .catch(err => {
        setLoading(false);
      });

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getEvent();
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{event.title}</title>
        <meta name="description" content={event.meta_description} />
      </Helmet>

      {!loading && !event.slug && (
        <Box>
          <Container maxW={'5xl'}>
            <Heading
              as={'h3'}
              fontFamily="american"
              fontWeight={500}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              lineHeight={'100%'}
              textAlign="left"
              mt={{ base: 10, md: 5 }}>
              404 Page Not Found
            </Heading>
            <Box textAlign="left">
              <Button as={Link} href="/">Go to Home Page</Button>
            </Box>
          </Container>
        </Box>
      )}

      {event.slug && (
        <Box>
          {event.header_img ? (
            <Box className="home-header-container" backgroundImage={`url(${event.header_img})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60dvh">
              <Container maxW={'5xl'}>

              </Container>
            </Box>
          ) : null}
          <Box bg="#15315A">
            <Container maxW={'5xl'}>
              <Grid templateColumns='repeat(6, 1fr)'>
                <GridItem colSpan={{ base: 12, md: 6 }}>
                  <Text
                    as="h1"
                    textAlign="left"
                    fontFamily="american"
                    fontWeight={500}
                    fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                    lineHeight={'100%'}
                    color="#BDECDE"
                    mt={{ base: 4, md: 0 }}
                  >{event.title}</Text>
                </GridItem>
              </Grid>
            </Container>
          </Box>
          <Box bg="#BEE3DA">
            <Container maxW={'7xl'}>
              <Stack direction={{ base: 'column', md: 'row' }}>
                <Box w={{ base: '100%', md: '50%', lg: '75%' }} >
                  {event.main_img ? (
                    <Image
                      src={`${event.main_img}`}
                      layout={'fill'}
                      w={'100%'}
                      roundedBottomLeft={20}
                      alt={'main image'}
                    />
                  ) : null}
                </Box>
                <Box w={{ base: '100%', md: '50%', lg: '25%' }} textAlign="left" bg="white" pl={10} pr={10} roundedBottomRight={20}>
                  <Heading
                    as={'h3'}
                    fontFamily="american"
                    fontWeight={500}
                    fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                    lineHeight={'100%'}
                    textAlign="left"
                    mt={{ base: 10, md: 5 }}>
                    {event.destination_name}
                  </Heading>
                  {event.destination ? (
                    <Box>
                      <Text color="gray.500">{event.destination.address}<br />
                        {event.destination.city}, {event.destination.state} {event.destination.zip}</Text>
                      <Link href={`tel:+1${event.destination.phone}`} fontSize="16px;">{event.destination.phone}</Link>
                    </Box>
                  ) : null}
                  {event.content ? (
                    <Box className="content-block" mt={5}>
                      {parser(event.content)}
                    </Box>
                  ) : null}
                  {event.user_data ? (
                    <Box>
                      <Center>
                        <Image
                          src={`${event.user_img}`}
                          layout={'fill'}
                          w={'50%'}
                          alt={'photo'}
                        />
                      </Center>
                      <Text mt={2} mb={10}>Itinerary By <strong>{event.user_data.display_name}</strong> from <strong>{event.user_data.city}</strong></Text>
                    </Box>
                  ) : null}
                </Box>
              </Stack>

              </Container>

              <Container maxW={'7xl'} pb={10}>
              {event.items_list?.map((item, index) => (
                <Box bg="white" borderRadius={10} m={2} p={4}>
                  <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                    <Box>
                      <Link href={`/d/${item.destination.slug}`} target="_blank"><Image src={`${item.main_img}`} alt={`Itinerary step ${index + 1} image`} minHeight="400px" height="100%" /></Link>
                    </Box>
                    <Box>
                      <Heading
                        as={'h3'}
                        fontFamily="american"
                        fontWeight={500}
                        fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                        lineHeight={'100%'}
                        mt={{ base: 10, md: 5 }}>
                        #{index + 1}<br />{item.destination_name}
                      </Heading>

                      {item.event ? (
                      <Heading
                      as={'h4'}
                      fontFamily="american"
                      fontWeight={500}
                      fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                      lineHeight={'100%'}
                      mt={{ base: 10, md: 5 }}>
                      Event: {item.event.title}
                    </Heading>
                      ) : null}
 
                      {item.content ? (
                      <Center>{parser(item.content)}</Center>
                      ) : null}

                      {item.event ? (
                        <Button as={Link} href={`/events/${item.event.slug}`} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white" mb={5}>Event Page</Button>
                      ) : null}

                      {item.destination.website ? (
                        <Button as={Link} href={`${item.destination.website}`} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white" mb={5}>Destination Website</Button>
                      ) : null}
                      <br />
                      {item.destination.address ? (
                        <Button as={Link} href={`https://www.google.com/maps/dir/?api=1&destination=${item.destination.address} ${item.destination.city} ${item.destination.state} ${item.destination.zip}`} target="_blank" borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" bg="#F56A18" color="white"  mb={5}>Get Directions</Button>
                      ) : null}
                    </Box>
                    <Box>
                    {item.destination.location_formatted_latitude ? (
                      <MapContainer center={[item.destination.location_formatted_latitude,item.destination.location_formatted_longitude]} zoom={16} scrollWheelZoom={false} style={{ minHeight: '400px' }}>
                        <ChangeView center={[item.destination.location_formatted_latitude,item.destination.location_formatted_longitude]} zoom={16} />
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LocationMarker destination={item.destination} />
                      </MapContainer>
                       ) : null}
                    </Box>
                  </SimpleGrid>
                </Box>
              ))}

              </Container>

              <MapContainer center={position} zoom={10} scrollWheelZoom={false} style={{ height: '600px' }}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarkers />
              </MapContainer>

          </Box>
        </Box>
      )}
    </PageSlideFade >
  );
};

export default List;
